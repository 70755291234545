import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: "tr",
    fallbackLng: "tr",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          leftMenu: {
            dashboard: "Home",
            meta4: "MT4",
            kullaniciListesi: "User List",
            tradesListesi: "Trades List",
            tradesZamanListesi: "Trades Time List",
            personel: "Employee",
            personelEkle: "Employee Add",
            personelListesi: "Employee List",
            musteri: "Customer",
            musteriEkle: "Customer Add",
            topluMusteriEkle: "All Customer Add",
            topluMusteriEkleSorumluLead:
              "Add Bulk Customer (With Responsible and Lead)",
            googleSheetsListesi: "Google Sheets List",
            musteriListesi: "Customer List",
            musteriIndirmeListesi: "Customer Download List",
            takim: "Team",
            takimEkle: "Team Add",
            takimListesi: "Team List",
            lead: "Lead",
            leadEkle: "Lead Add",
            leadListesi: "Lead List",
            rol: "Role",
            rolEkle: "Role Add",
            rolListesi: "Role List",
            yonetim: "Management",
            tumMusteriNotlari: "All Customer Notes",
            tumHareketGecmisi: "All Movement History",
            tumMusteriSorumluKisiGecmisi: "All Customer Contact History",
            musteriBilgileriRaporu: "Customer Information Report",
            dokumantasyon: "Documentation",
            rolVeYetkilendirme: "Role and Authorization",
            yetki: "Authority",
            yetkiEkle: "Authority Add",
            yetkiListesi: "Authority List",
            kaynak: "Kaynak",
            kaynakEkle: "Kaynak Ekle",
            kaynakListesi: "Kaynak Listesi",
            ticket: "Ticket",
            ticketEkle: "Tick Ekle",
            ticketListesi: "Ticket Listesi",
          },
          global: {
            placeholderSelect: "Please Choose",
            placeholderInput: "Please fill in the field",
            tablePlaceholderSearchText: "Search",
            btnSaveText: "Save",
            btnUpdateText: "Update",
            tableNoData: "No Records Found",
            tableAramayiTemizle: "Clear Search",
            tableGenelArama: "General Search",
            headerInputSearch:
              "Please search for a customer by entering a phone number.",
            btnSendText: "Gönder",
          },
          personel: {
            personelEkleBaslik: "Employee",
            personelEkleAciklama:
              "You can add staff by filling out the information below.",
            personelDetayBaslik: "Employee",
            personelDetayAciklama: "You can edit the following information.",
            personelDetaySifremiGuncelle: "Update My Password",
            personelInputAdıSoyad: "Name Surname",
            personelInputEmail: "E-mail address",
            personelInputSifre: "Password",
            personelInputSifreTekrar: "Password Repeat",
            personelInputTakim: "Team",
            personelInputRol: "Role",
            personelInputYetki: "Authority",
            personelInputKullaniciAdi: "User name",
          },
          personelListesi: {
            personelListesiBaslik: "List of staff",
            personelListesiAciklama: "Below you can see the Staff List.",
            tabloAdiSoyadi: "Name and surname",
            tabloEPosta: "E-Mail",
            tabloRol: "Role",
            tabloKullaniciAdi: "User Name",
            tabloOlusturan: "Constituent",
            tabloOlusturmaTarihi: "Creation Date",
          },
          anasayfa: {
            kendiRaporlarim: "My Own Reports",
            kendiRaporlarimBaslik: "General Lead Status of My Customers",
            kendiActigimNotlar: "My Own Notes",
            kendiActigimNotlarBaslik: "Customer Notes",
            kendiActigimNotlarMusteriDetay: "Go to Customer Detail",
            kendiActigimNotlarAciklama:
              "You can see the notes of all the customers you opened below.",
            tumMusteriNotlari: "All Customer Notes",
            tumMusteriNotlariBaslik: "Customer Notes",
            tumMusteriNotlariAciklama:
              "Below You Can See the Notes of All Customers of the Selected Staff:",
            tumsMusteriNotlariListeBaslik:
              "The Customer Note Opened by the Staff Does Not Exist",
            tumMusteriNotlariOlusturmaTarihi: "Creation Date",
            tumMusteriNotlariMusteri: "Customer",
            tumMusteriNotlariOlusturan: "Constituent",
            tumMusteriNotlariPersonel: "Employee",
            tumHareketGecmisi: "All Movement History",
            tumHareketGemicisiBaslik: "Movement History",
            tumHareketGemicisiBulunamadi: "No Activity History Found",
            tumHareketGecmisiAciklama: "You can view the insult history below.",
            tumHareketGecmisiTabloHareketİcerigi: "Movement Content",
            tumHareketGecmisiTabloHareketTarihi: "Departure date",
            tumMusteriSorumluKisiGecmisi: "All Customer Contact History",
            tumMusteriSorumluKisiGecmisiBaslik:
              "Customer Responsible Personnel Change History",
            tumMusteriSorumluKisiGecmisiAciklama:
              "You Can See Customer Responsible Personnel Change History Below.",
            tumMusteriSorumluKisiGecmisiTabloMusteriId: "Customer Id",
            tumMusteriSorumluKisiGecmisiTabloMusteriAdi: "Customer Name",
            tumMusteriSorumluKisiGecmisiTabloMusteriSoyadi: "Customer Surname",
            tumMusteriSorumluKisiGecmisiTabloMusteriTelefon: "Customer Phone",
            tumMusteriSorumluKisiGecmisiTabloSuanSorumlu:
              "Currently Responsible Person",
            tumMusteriSorumluKisiGecmisiTabloDegisiklikTarihi: "Date of Change",
          },
          takim: {
            takimEkleBaslik: "Team",
            takimEkleAciklama:
              "You can add a team by filling out the information below.",
            takimDetayBaslik: "Team",
            takimDetayAciklama:
              "You can see the information of the team you selected below.",
            takimInputAdi: "Team Name",
          },
          lead: {
            leadEklemeBaslik: "Lead",
            leadEklemeAciklama:
              "You can add a lead by filling out the information below..",
            leadDetayBaslik: "Lead",
            leadDetayAciklama:
              "You can see the information of the lead you have chosen below..",
            leadInputAdi: "Lead Name",
          },
          leadListesi: {
            leadListesiBaslik: "Lead List",
            leadListesiAciklama: "You Can See The Lead List Below.",
            tabloLeadAdi: "Lead Name",
            tabloOlusturan: "Constituent",
            tabloOlusturmaTarihi: "Creation Date",
            tabloGuncellemeTarihi: "Date of update",
          },
          kaynakListesi: {
            sourceListesiBaslik: "source List",
            ListesiAciklama: "You Can See The Lead List Below.",
            tabloSourceAdi: "Lead Name",
            tabloOlusturan: "Constituent",
            tabloOlusturmaTarihi: "Creation Date",
            tabloGuncellemeTarihi: "Date of update",
          },
          rol: {
            rolEkleBaslik: "Role",
            rolEkleAdi: "Role Name",
            rolEkleAciklama:
              "You can add a role by filling out the information below..",
            rolDetayBaslik: "Role Detail",
            rolDetayAciklama:
              "You can see the information of the role you have chosen below..",
          },
          rolListesi: {
            rolListesiBaslik: "Role List",
            rolListesiAciklama: "You Can See The Role List Below.",
            tabloRolAdi: "Role Name",
            tabloOlusturan: "Constituent",
            tabloOlusturmaTarihi: "Creation Date",
            tabloGuncellemeTarihi: "date of update",
          },
          login: {
            title: "Welcome to Staff Login",
            description:
              "Please fill in the required fields to enter personnel.",
            userName: "User Name",
            password: "Password",
            beniHatirla: "Remember My Login",
            button: "Login",
          },
        },
      },
      tr: {
        translation: {
          leftMenu: {
            dashboard: "Anasayfa",
            meta4: "MT4",
            kullaniciListesi: "Kullanıcı Listesi",
            tradesListesi: "Trades Listesi",
            tradesZamanListesi: "Trades Zaman Listesi",
            personel: "Personel",
            personelEkle: "Personel Ekle",
            personelListesi: "Personel Listesi",
            musteri: "Müşteri",
            musteriEkle: "Müşteri Ekle",
            topluMusteriEkle: "Toplu Müşteri Ekle",
            topluMusteriEkleSorumluLead:
              "Toplu Müşteri Ekle (Sorumlu ve Lead İle)",
            googleSheetsListesi: "Google Sheets Listesi",
            musteriListesi: "Müşteri Listesi",
            musteriIndirmeListesi: "Müşteri İndirme Listesi",
            takim: "Takım",
            takimEkle: "Takım Ekle",
            takimListesi: "Takım Listesi",
            lead: "Lead",
            leadEkle: "Lead Ekle",
            leadListesi: "Lead Listesi",
            rol: "Rol",
            rolEkle: "Rol Ekle",
            rolListesi: "Rol Listesi",
            yonetim: "Yönetim",
            tumMusteriNotlari: "Tüm Müşteri Notları",
            tumHareketGecmisi: "Tüm Hareket Geçmişi",
            tumMusteriSorumluKisiGecmisi: "Tüm Müşteri Sorumlu Kişi Geçmişi",
            musteriBilgileriRaporu: "Müşteri Bilgileri Raporu",
            dokumantasyon: "Dokümantasyon",
            rolVeYetkilendirme: "Rol ve Yetkilendirme",
            yetki: "Yetki",
            yetkiEkle: "Yetki Ekle",
            yetkiListesi: "Yetki Listesi",
            kaynak: "Kaynak",
            kaynakEkle: "Kaynak Ekle",
            kaynakListesi: "Kaynak Listesi",
            ticket: "Ticket",
            ticketEkle: "Tick Ekle",
            ticketListesi: "Ticket Listesi",
          },
          global: {
            placeholderSelect: "Lütfen Seçim Yapınız",
            placeholderInput: "Lütfen Alanı Doldurunuz",
            tablePlaceholderSearchText: "Arama Yapınız",
            btnSaveText: "Kaydet",
            btnUpdateText: "Güncelle",
            tableNoData: "Kayıt Bulunamadı",
            tableAramayiTemizle: "Aramayı Temizle",
            tableGenelArama: "Genel Arama",
            headerInputSearch:
              "Lütfen Telefon Numarası Girerek Müşteri Aratınız.",
            btnSendText: "Gönder",
          },
          personel: {
            personelEkleBaslik: "Personel",
            personelEkleAciklama:
              "Aşağıda ki bilgileri doldurarak personel ekleyebilirsiniz.",
            personelDetayBaslik: "Personel",
            personelDetayAciklama: "Aşağıda ki bilgileri düzenleyebilirsiniz.",
            personelDetaySifremiGuncelle: "Şifremi Güncelle",
            personelInputAdıSoyad: "Adı Soyad",
            personelInputEmail: "E-Posta Adresi",
            personelInputSifre: "Şifre",
            personelInputSifreTekrar: "Şifre Tekrar",
            personelInputTakim: "Takım",
            personelInputRol: "Rol",
            personelInputYetki: "Yetki",
            personelInputKullaniciAdi: "Kullanıcı Adı",
          },
          personelListesi: {
            personelListesiBaslik: "Personel Listesi",
            personelListesiAciklama:
              "Aşağıda Personel Listesini Görebilirsiniz.",
            tabloAdiSoyadi: "Adı Soyadı",
            tabloEPosta: "E-Posta",
            tabloRol: "Rol",
            tabloKullaniciAdi: "Kullanıcı Adı",
            tabloOlusturan: "Oluşturan",
            tabloOlusturmaTarihi: "Oluşturma Tarihi",
          },
          musteri: {
            baslik: "Müşteri",
            aciklama:
              "Aşağıda ki bilgileri doldurarak müşteri ekleyebilirsiniz.",
            musteriDetayBaslik: "Müşteri",
            musteriDetayAciklama: "Aşağıda ki bilgileri düzenleyebilirsiniz.",
            adi: "Müşteri Adı",
            soyadi: "Müşteri Soyadı",
            telefon: "Müşteri Telefon",
            telefon2: "Müşteri Telefon 2",
            email: "Müşteri E-Posta",
            sorumlu: "Sorumlu Personel",
            lead: "Lead Durumu",
            telefonKodu: "Telefon Alan Kodu",
            kaynak: "Kaynak",
            metaId: "Meta4 ID",
            not: "Müşteri Notu",
          },
          musteriListesi: {
            baslik: "Müşteri Listesi",
            aciklama: "Aşağıda Müşteri Listesini Görebilirsiniz.",
            tumMusterileriSec:
              "Müşteri Listesinde Yer Alan Tüm Müşterileri Seç",
            adiSoyadi: "Adı Soyadı",
            telefon: "Telefon",
            lead: "Lead Durumu",
            musteriId: "Müşteri ID",
            metaId: "Meta4 ID",
            kaynak: "Kaynak",
            kaynak2: "Kaynak 2",
            kaynak3: "Kaynak 3",
            sorumlu: "Sorumlu Personel",
            olusturma: "Oluşturma Tarihi",
            guncelleme: "Güncelleme Tarihi",
          },
          topluMusteriEkle: {
            topluMusteriEkleBaslik: "Toplu Müşteri Ekle",
            sorumluPersonel: "Sorumlu Personel",
            leadDurumu: "Lead Durumu",
            reklamDatasiTitle: "Reklam Datası Seçimi",
            reklamDatasi: "Reklam Datası Mı?",
            aktar: "Aktar",
            sira: "Sıra",
            adi: "Adı",
            soyadi: "Soyadı",
            telefon: "Telefon",
            ornekExcellText: "Örnek excell'i indirmek için",
            tiklayiniz: "Tıklayınız.",
          },
          topluMusteriEkleSorumluLead: {
            Baslik: "Lead ve Sorumlu Personel İle Toplu Müşteri Ekle",
            aktar: "Aktar",
            sira: "Sıra",
            adi: "Adı",
            soyadi: "Soyadı",
            telefon: "Telefon",
          },
          googleSheetsList: {
            baslik: "Google Sheet Müşteri Listesi",
            aciklama:
              "Aşağıda Google Sheet tarafından gelen müşterileri görebilir ve müşteri listesine aktarabilirsiniz.",
            sorumluPersonel: "Sorumlu Personel",
            leadDurumu: "Lead Durumu",
            aktar: "Aktar",
            sira: "Sıra",
            adi: "Adı",
            soyadi: "Soyadı",
            telefon: "Telefon",
            kaynak: "Kaynak",
            tarih: "Tarih",
          },
          anasayfa: {
            kendiRaporlarim: "Kendi Raporlarım",
            kendiRaporlarimBaslik: "Müşterilerimin Genel Lead Durumları",
            kendiActigimNotlar: "Kendi Açtığım Notlar",
            kendiActigimNotlarBaslik: "Müşteri Notları",
            kendiActigimNotlarMusteriDetay: "Müşteri Detayına Git",
            kendiActigimNotlarAciklama:
              "Aşağıda Tüm Açtığınız Müşterilerin Notlarını Görebilirsiniz.",
            tumMusteriNotlari: "Tüm Müşteri Notları",
            tumMusteriNotlariBaslik: "Müşteri Notları",
            tumMusteriNotlariAciklama:
              "Aşağıda Seçilen Personelin Tüm Müşterilerinin Notlarını Görebilirsiniz:",
            tumsMusteriNotlariListeBaslik:
              "Personelin Açmış Olduğu Müşteri Notu Mevcut Değil",
            tumMusteriNotlariOlusturmaTarihi: "Oluşturma Tarihi",
            tumMusteriNotlariMusteri: "Müşteri",
            tumMusteriNotlariOlusturan: "Oluşturan",
            tumMusteriNotlariPersonel: "Personel",
            tumHareketGecmisi: "Tüm Hareket Geçmişi",
            tumHareketGemicisiBaslik: "Hareket Geçmişi",
            tumHareketGemicisiBulunamadi: "Hareket Geçmişi Bulunamadı",
            tumHareketGecmisiAciklama:
              "Hakeret Geçmişini Aşağıdan Görebilirsiniz.",
            tumHareketGecmisiTabloHareketİcerigi: "Hareket İçeriği",
            tumHareketGecmisiTabloHareketTarihi: "Hareket Tarihi",
            tumMusteriSorumluKisiGecmisi: "Tüm Müşteri Sorumlu Kişi Geçmişi",
            tumMusteriSorumluKisiGecmisiBaslik:
              "Müşteri Sorumlu Personel Değişiklik Geçmişi",
            tumMusteriSorumluKisiGecmisiAciklama:
              "Müşteri Sorumlu Personel Değişiklik Geçmişini Aşağıdan Görebilrisiniz.",
            tumMusteriSorumluKisiGecmisiTabloMusteriId: "Müşteri Id",
            tumMusteriSorumluKisiGecmisiTabloMusteriAdi: "Müşteri Adı",
            tumMusteriSorumluKisiGecmisiTabloMusteriSoyadi: "Müşteri Soyadı",
            tumMusteriSorumluKisiGecmisiTabloMusteriTelefon: "Müşteri Telefon",
            tumMusteriSorumluKisiGecmisiTabloSuanSorumlu: "Şuan Sorumlu Kişi",
            tumMusteriSorumluKisiGecmisiTabloDegisiklikTarihi:
              "Değişiklik Tarihi",
          },
          takim: {
            takimEkleBaslik: "Takım",
            takimEkleAciklama:
              "Aşağıdaki bilgileri doldurarak takım ekleyebilirsiniz.",
            takimDetayBaslik: "Takım",
            takimDetayAciklama:
              "Seçtiğiniz Takımın Bilgilerini Aşağıda Görebilirsiniz.",
            takimInputAdi: "Takım Adı",
          },
          ticket: {
            ticket: "Ticket",
            ticketAciklama:
              "Aşağıdaki bilgileri doldurarak ticket oluşturabilirsiniz",
            ticketInputBaslik: "Başlık Alanı",
            ticketInputAciklama: "Açıklama Alanı",
            ticketInputOnemi: "Önem Düzeyi",
            ticketInputBolum: "Bölüm",
            ticketInputSorumlu: "Sorumlu Personel",
            ticketDetayBaslik: "Ticket",
            ticketDetayAciklama:
              "Seçtiğiniz Ticket Bilgilerini Aşağıda Görebilirsiniz",
          },
          takimListesi: {
            takimListesiBaslik: "Takım Listesi",
            takimListesiAciklama: "Aşağıda Takım Listesini Görebilirsiniz.",
            tabloTakimAdi: "Takım Adı",
            tabloOlusturan: "Oluşturan",
            tabloOlusturmaTarihi: "Oluşturma Tarihi",
            tabloGuncellemeTarihi: "Güncelleme Tarihi",
          },
          lead: {
            leadEklemeBaslik: "Lead",
            leadEklemeAciklama:
              "Aşağıda ki bilgileri doldurarak lead ekleyebilirsiniz.",
            leadDetayBaslik: "Lead",
            leadDetayAciklama:
              "Seçtiğiniz Lead'ın Bilgilerini Aşağıda Görebilirsiniz.",
            leadInputAdi: "Lead Adı",
          },
          kaynak: {
            kaynakEklemeBaslik: "kaynak",
            kaynakEklemeAciklama:
              "Aşağıda ki bilgileri doldurarak kaynak ekleyebilirsiniz",
            KaynakDetayBaslik: "Kaynak",
            kaynakDetayAciklama:
              "Seçtiğiniz Kaynak'ın Bilgilerini Aşağıda Görebilirsiniz.",
            kaynakInputAdi: "Kaynak Adı",
          },
          leadListesi: {
            leadListesiBaslik: "Lead Listesi",
            leadListesiAciklama: "Aşağıda Lead Listesini Görebilirsiniz.",
            tabloLeadAdi: "Lead Adı",
            tabloOlusturan: "Oluşturan",
            tabloOlusturmaTarihi: "Oluşturma Tarihi",
            tabloGuncellemeTarihi: "Güncelleme Tarihi",
          },
          KaynakListesi: {
            KaynakListesiBaslik: "Kaynak Listesi",
            KaynakListesiAciklama: "Aşağıda kaynak Listesini Görebilirsiniz.",
            tabloKaynakAdi: "Kaynak Adı",
            tabloOlusturan: "Oluşturan",
            tabloOlusturmaTarihi: "Oluşturma Tarihi",
            tabloGuncellemeTarihi: "Güncelleme Tarihi",
          },
          rol: {
            rolEkleBaslik: "Rol",
            rolEkleAdi: "Rol Adı",
            rolEkleAciklama:
              "Aşağıdaki Bilgileri Doldurarak Rol Ekleyebilirsiniz.",
            rolDetayBaslik: "Rol Detay",
            rolDetayAciklama:
              "Seçtiğiniz Rol'ün Bilgilerini Aşağıda Görebilirsiniz.",
          },
          rolListesi: {
            rolListesiBaslik: "Rol Listesi",
            rolListesiAciklama: "Aşağıda Rol Listesini Görebilirsiniz.",
            tabloRolAdi: "Rol Adı",
            tabloOlusturan: "Oluşturan",
            tabloOlusturmaTarihi: "Oluşturma Tarihi",
            tabloGuncellemeTarihi: "Güncelleme Tarihi",
          },
          login: {
            title: "Personel Girişine Hoş Geldiniz",
            description:
              "Personel girişi yapmak için lütfen gerekli alanları doldurunuz.",
            userName: "Kullanıcı Adı",
            password: "Şifre",
            beniHatirla: "Giriş Bilgilerimi Hatırla",
            button: "Giriş Yap",
          },
        },
      },
    },
  });
export default i18n;
