import jwt_decode from "jwt-decode";

export class JwtHelper {

    /**
     * Token içerisinde ki tüm datayı getirir. (Username,roles,exp_time vb.)
     * @returns {unknown}
     */
    static getJwtObj() {
        return jwt_decode(localStorage.getItem("token"));
    }

    /**
     * Token içeriisinde ki kullanıcı adını getirir.
     * @returns {*}
     */
    static getUsername() {
        return this.getJwtObj().sub;
    }

    /**
     * Token içeriisinde ki kullanıcı id'sini getirir.
     * @returns {*}
     */
     static getUserId() {
        return this.getJwtObj().id;
    }

    /**
     * Token içeriisinde ki kullanıcı role'ünü getirir.
     * @returns {*}
     */
     static getUserRole() {
        return this.getJwtObj().role;
    }

    /**
     * Token içeriisinde ki kullanıcıyı getirir.
     * @returns {*}
     */
     static getUser() {
        return this.getJwtObj();
    }

    /**
     * Token içerisindeki kullanıcının yetkileri getirir.
     * @returns {*}
     */
    static getAuthority() {
        return this.getJwtObj().authority;
    }

    /**
     * Aradığımız rolün token içerisinde ki kullanıcının rollerinde olup olmadığına bakar.
     * @param role
     * @returns {*}
     */
    static isRoleExist(role) {
        return this.getJwtObj().roles.includes(role);
    }

    static isConnectPanel(){
        return this.getJwtObj().isConnectPanel;
    }

    /**
     * Bu metot elimizde ki tokenın hala geçerli olup olmaığını kontrol eder. Eğer metot false dönerse login ekranına yönlendirilmelidir.
     * @returns {boolean}
     */
    static isValid() {
        try {
            let expDate = this.getJwtObj().exp;
            let epochToDate = new Date(0);
            epochToDate.setUTCSeconds(expDate);
            return epochToDate > new Date();
        } catch (e) {
            return false;
        }
    }
}