import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';

// plugins
import moment from 'moment';

// Prime Component
import {DataTable} from 'primereact/datatable';
import {confirmPopup} from 'primereact/confirmpopup';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';

/*Component*/
import ContentLoading from '../loading/ContentLoading';

/*Service*/
import {AuthoritiesGet} from '../../service/authorities';
import {momentDateFormat} from "../../constants/string";


const List = () => {
    const [isLoading, setIsLoading] = useState(true);
    const toast = useRef(null);
    const [data, setData] = useState([]); //Takım Listesi
    const [filters1, setFilters1] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue1] = useState('');

    useEffect(() => {
        AuthoritiesGet().then(res => {
            setIsLoading(false)
            setData(res)
        })
        initFilters1();
    }, []);


    //Listelemeye düzenleme ve silme butonunu eklediğimiz kısım
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Link className="p-button-rounded p-button-success" to={`/yetki/${rowData.id}`}>
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" />
                </Link>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger  mr-2" tooltip="Takımı Sil" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} 
                        onClick={() => {
                            confirm2(rowData.id)
                        }}
                />
            </React.Fragment>
        );
    }


    //Silme İşlemleri Başlar
    const accept = (id) => {
        //Silme işlemi evet diyince
        // TeamDetele(id).then(res => {
        //     setIsLoading(false)
        // }).catch(err => {
        //     setIsLoading(false);
            
        //     toast.current.show({severity: 'error', summary: 'Hata', detail: `${err.response.data}`, life: 3000});
        // })
    };

    const reject = () => {
        //Silme işlemi hayır diyince
    };

    //Silme Butonu Emin Misiniz Sorusu?
    const confirm2 = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Silme İşlemini Yapmak İstediğinize Emin misiniz?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Evet',
            rejectLabel: 'Hayır',
            accept: () => accept(event),
            reject
        });
    };


    const momentCreatedBodyTemplate = (rowData) => {
        if (rowData.createdDate === null) return "";
        return moment(rowData.createdDate).format(momentDateFormat)
    }

    const momentUpdatedBodyTemplate = (rowData) => {
        if (rowData.updatedDate === null) return "";
        return moment(rowData.updatedDate).format(momentDateFormat)
    }

    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);
        setGlobalFilterValue1(value);
    }
    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'code': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'createdUser': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
        });
        setGlobalFilterValue1('');
    }
    const clearFilter1 = () => {
        initFilters1();
    }

    const renderHeader1 = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Aramayı Temizle" className="p-button-outlined" onClick={clearFilter1} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Genel Arama Yapınız" />
                </span>
            </div>
        )
    }
    const header1 = renderHeader1();

    return (
        <>
            {isLoading ? (
                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <ContentLoading/>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="grid">
                    <Toast ref={toast} />
                    <div className="col-12">
                        <div className="card">
                            <h5>Yetki Listesi</h5>
                            <p>Yetki listesinde yer alanlar aşağıda listelenmektedir.</p>
                            <DataTable value={data} responsiveLayout="scroll" paginator rows={10} header={header1} emptyMessage="Kayıt Bulunamadı"
                                filters={filters1} filterDisplay="menu"
                                dataKey="id" 
                                globalFilterFields={['name', 'createdUser', 'code']} rowsPerPageOptions={[10,20,30,40,50,100,150,200,250,300]}>
                                <Column field="name" header="Yetki İsmi"></Column>
                                <Column field="code" header="Yetki Kodu"></Column>
                                <Column field="createdUser" header="Oluşturan"></Column>
                                <Column field="createdDate" header="Oluşturma Tarihi" body={momentCreatedBodyTemplate}
                                        filterPlaceholder="Arama Yapın"></Column>
                                <Column field="updatedDate" header="Güncelleme Tarihi" body={momentUpdatedBodyTemplate}
                                        filterPlaceholder="Arama Yapın"></Column>
                                <Column body={actionBodyTemplate} exportable={false}
                                        style={{minWidth: '8rem'}}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>

            )}
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(List, comparisonFn);
