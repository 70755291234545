import {api} from '../constants/string';
import {HTTP} from "../axios/axios";

export function Mt4UsersGetList(page,size) { 
    return new Promise((res, rej) =>
        HTTP.get(`${api}/mt4/users?page=${page}&size=${size}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}
export function Mt4UsersModifyTimeDescGetList(page,size) { 
    return new Promise((res, rej) =>
        HTTP.get(`${api}/mt4/users/modify-time-desc?page=${page}&size=${size}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}
export function Mt4TradesGetList(page,size) { 
    return new Promise((res, rej) =>
        HTTP.get(`${api}/mt4/trades?page=${page}&size=${size}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}
export function Mt4TradesModifyTimeDescGetList(page,size) { 
    return new Promise((res, rej) =>
        HTTP.get(`${api}/mt4/trades/modify-time-desc?page=${page}&size=${size}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}
export function Mt4TradesByLoginGetList(id,page,size) { 
    return new Promise((res, rej) =>
        HTTP.get(`${api}/mt4/trades/by-login/${id}?page=${page}&size=${size}&sort=openTime,desc`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}
export function Mt4TradesByLoginCurrencyGetList(id) { 
    return new Promise((res, rej) =>
        HTTP.get(`${api}/mt4/trades/by-login/${id}/currency`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}