import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "../AppTopbar";
import { AppFooter } from "../AppFooter";
import { AppMenu } from "../AppMenu";
import { AppConfig } from "../AppConfig";

import Dashboard from "../components/dashboard/Dashboard";

import PersonnelAdd from "../components/personnel/add";
import PersonnelDetail from "../components/personnel/detail";
import personnelList from "../components/personnel/list";

import CustomerAdd from "../components/customer/Add";
import CustomerDetail from "../components/customer/Detail";
import CustomerList from "../components/customer/List";
import CustomerAddBulk from "../components/customer/AddBulkCustomers";
import CustomerAddBulkLeadPersonnel from "../components/customer/AddCustomerBulkLeadPersonnel";
import googleSheetsList from "../components/customer/GoogleSheetsList";
import WebFormList from "../components/customer/WebFormList";
import PasifCustomerList from "../components/customer/PasifCustomerList";

import RoleAdd from "../components/role/Add";
import RoleDetail from "../components/role/Detail";
import RoleList from "../components/role/List";

import TeamAdd from "../components/team/Add";
import TeamDetail from "../components/team/Detail";
import TeamList from "../components/team/List";

import NotificationList from "../components/notification/List";

import SupportAdd from "../module/support/Index";
import SupportList from "../module/support/List";

import LeadAdd from "../components/lead/Add";
import LeadDetail from "../components/lead/Detail";
import LeadList from "../components/lead/List";

import SourceAdd from "../components/source/Add";
import SourceDetail from "../components/source/Detail";
import SourceList from "../components/source/List";

import AuthoritiesAdd from "../components/authorities/Add";
import AuthoritiesList from "../components/authorities/List";

import CustomerStatusList from "../components/log/CustomerStatusList";
import LogList from "../components/log/List";
import AllCustomerNote from "../components/note/allCustomerNote";

import RoleAndAuthorizationDokumantion from "../components/documantion/RoleAndAuthorizationDokumantion";

import UserList from "../components/mt4/UsersList";
import TradesList from "../components/mt4/TradesList";
import TradesModifyTimeDescGetList from "../components/mt4/TradesModifyTimeDescGetList";

import TicketAdd from "../components/ticket/Add";
import TicketList from "../components/ticket/List";
import TicketDetail from "../components/ticket/Detail";
// import CustomerInfo from "../components/customer/CustomerInfo";

/*Yetkilendirme ve Rol*/
import {
  destekMenu,
  destekListesi,
  destekOlusturma,
  dokumanMenu,
  yonetimMenu,
  musteriMenu,
  rolMenu,
  leadMenu,
  personelMenu,
  takimMenu,
  tumMusteriNotlar,
  tumHareketGecmisi,
  meta4Listeleri,
  tumMusteriSorumluKisiGecmisi,
  anasayfa,
  musteriEkle,
  musteriListesi,
  googleSheetsListesi,
  topluMusteriEkle,
  personelEkle,
  personelDetay,
  personelListesi,
  rolEkleme,
  rolListesi,
  takimEkle,
  takimListesi,
  leadEkle,
  leadListesi,
  customerInfoAggregation,
} from "../constants/string";

//import ErrorPage from '../pages/ErrorPage';
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "../assets/demo/flags/flags.css";
import "../assets/demo/Demos.scss";
import "../assets/layout/layout.scss";
import "../App.js";

import { Toast } from "primereact/toast";
/*Plugins*/
import { useTranslation } from "react-i18next";
import { JwtHelper } from "../plugins/jwtHelper";
import customerInfo from "../components/customer/CustomerInfo";

//import devtools from '../../node_modules/devtools-detect/index.js';

const Main = () => {
  const { t } = useTranslation();
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const copyTooltipRef = useRef();
  const location = useLocation();
  PrimeReact.ripple = true;
  let menuClick = false;
  let mobileTopbarMenuClick = false;
  const toast = useRef(null);

  //Token kontrol noktası
  useEffect(() => {
    if (!JwtHelper.isValid()) {
      localStorage.setItem("token", "");
      window.location.reload();
      window.location.hash = "/giris";
    }

    setInterval(() => {
      if (!JwtHelper.isValid()) {
        localStorage.setItem("token", "");
        window.location.reload();
        window.location.hash = "/giris";
      }
    }, 60000);
  }, []);

  //Console kısmının açılmasını engeller Başlar
  // useEffect(() => {
  //     if (devtools.isOpen) {
  //         while (true) {
  //             console.log("access denied")
  //         }
  //     }
  //     window.addEventListener('devtoolschange', event => {
  //     });

  //     document.addEventListener('contextmenu',(e)=>{
  //         e.preventDefault();
  //       }
  //       );
  //       document.onkeydown = function(e) {
  //       if(e.keyCode == 123) {
  //          return false;
  //       }
  //       if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
  //          return false;
  //       }
  //       if(e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
  //          return false;
  //       }
  //       if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
  //          return false;
  //       }
  //       if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
  //          return false;
  //       }
  //     }

  // }, [devtools.isOpen]);
  //Console kısmının açılmasını engeller biter

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };
  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const menu = [
    {
      label: t("leftMenu.dashboard"),
      yetki: anasayfa,
      items: [
        {
          label: t("leftMenu.dashboard"),
          icon: "pi pi-fw pi-th-large",
          to: "/",
          yetki: anasayfa,
        },
      ],
    },
    {
      label: t("leftMenu.meta4"),
      yetki: meta4Listeleri,
      items: [
        {
          label: t("leftMenu.kullaniciListesi"),
          icon: "pi pi-fw pi-th-large",
          to: "/mt4-kullanici-listesi",
          yetki: meta4Listeleri,
        },
        {
          label: t("leftMenu.tradesListesi"),
          icon: "pi pi-fw pi-th-large",
          to: "/mt4-trades-listesi",
          yetki: meta4Listeleri,
        },
        {
          label: t("leftMenu.tradesZamanListesi"),
          icon: "pi pi-fw pi-th-large",
          to: "/mt4-trades-degistirilmis-zaman-listesi",
          yetki: meta4Listeleri,
        },
      ],
    },
    {
      label: t("leftMenu.personel"),
      yetki: personelMenu,
      items: [
        {
          label: t("leftMenu.personelEkle"),
          icon: "pi pi-fw pi-user-plus",
          to: "/yeni-personel",
          yetki: personelEkle,
        },
        {
          label: t("leftMenu.personelListesi"),
          icon: "pi pi-fw pi-user",
          to: "/personel-listesi",
          yetki: personelListesi,
        },
      ],
    },
    {
      label: t("leftMenu.musteri"),
      yetki: musteriMenu,
      items: [
        {
          label: t("leftMenu.musteriEkle"),
          icon: "pi pi-fw pi-id-card",
          to: "/yeni-musteri",
          yetki: musteriEkle,
        },
        {
          label: t("leftMenu.topluMusteriEkle"),
          icon: "pi pi-fw pi-upload",
          to: "/yeni-toplu-musteri",
          yetki: topluMusteriEkle,
        },
        {
          label: t("leftMenu.topluMusteriEkleSorumluLead"),
          icon: "pi pi-fw pi-upload",
          to: "/yeni-toplu-musteri-sorumlu-lead",
          yetki: topluMusteriEkle,
        },
        {
          label: t("leftMenu.musteriListesi"),
          icon: "pi pi-fw pi-id-card",
          to: "/musteri-listesi",
          yetki: musteriListesi,
        },
        {
          label: t("leftMenu.googleSheetsListesi"),
          icon: "pi pi-fw pi-id-card",
          to: "/google-sheet-listesi",
          yetki: googleSheetsListesi,
        }, //googleSheetsListesi
        {
          label: "Web Form Listesi",
          icon: "pi pi-fw pi-id-card",
          to: "/web-form-listesi",
          yetki: googleSheetsListesi,
        },
        {
          label: "Pasif Müşteri Listesi",
          icon: "pi pi-fw pi-id-card",
          to: "/pasif-musteri-listesi",
          yetki: musteriListesi,
        },
        // {label: t("leftMenu.musteriIndirmeListesi"), icon: 'pi pi-fw pi-download', to: '/musteri-indirme-listesi', yetki: musteriIndirme, },
      ],
    },
    {
      label: "DESTEK",
      yetki: destekMenu,
      items: [
        {
          label: "Destek Talebi Oluştur",
          icon: "pi pi-fw pi-home",
          to: "/yeni-destek-talebi",
          badge: "YENİ",
          yetki: destekOlusturma,
        },
        {
          label: "Destek Talebi Listesi",
          icon: "pi pi-fw pi-home",
          to: "/destek-talebi-listesi",
          badge: "YENİ",
          yetki: destekListesi,
        },
      ],
    },
    {
      label: t("leftMenu.takim"),
      yetki: takimMenu,
      items: [
        {
          label: t("leftMenu.takimEkle"),
          icon: "pi pi-fw pi-user-plus",
          to: "/yeni-takim",
          yetki: takimEkle,
        },
        {
          label: t("leftMenu.takimListesi"),
          icon: "pi pi-fw pi-users",
          to: "/takim-listesi",
          yetki: takimListesi,
        },
      ],
    },
    {
      label: t("leftMenu.lead"),
      yetki: leadMenu,
      items: [
        {
          label: t("leftMenu.leadEkle"),
          icon: "pi pi-fw pi-sitemap",
          to: "/yeni-lead",
          yetki: leadEkle,
        },
        {
          label: t("leftMenu.leadListesi"),
          icon: "pi pi-fw pi-sitemap",
          to: "/lead-listesi",
          yetki: leadListesi,
        },
      ],
    },
    {
      label: t("leftMenu.kaynak"),
      yetki: rolMenu,
      items: [
        {
          label: t("leftMenu.kaynakEkle"),
          icon: "pi pi-fw pi-sitemap",
          to: "/yeni-kaynak",
          yetki: rolEkleme,
        },
        {
          label: t("leftMenu.kaynakListesi"),
          icon: "pi pi-fw pi-sitemap",
          to: "/kaynak-listesi",
          yetki: rolListesi,
        },
      ],
    },
    {
      label: t("leftMenu.rol"),
      yetki: rolMenu,
      items: [
        {
          label: t("leftMenu.rolEkle"),
          icon: "pi pi-fw pi-key",
          to: "/yeni-rol",
          yetki: rolEkleme,
        },
        {
          label: t("leftMenu.rolListesi"),
          icon: "pi pi-fw pi-key",
          to: "/rol-listesi",
          yetki: rolListesi,
        },
      ],
    },
    {
      label: t("leftMenu.yonetim"),
      yetki: yonetimMenu,
      items: [
        {
          label: t("leftMenu.tumMusteriNotlari"),
          icon: "pi pi-fw pi-star",
          to: "/tum-musteri-notlari",
          yetki: tumMusteriNotlar,
        },
        {
          label: t("leftMenu.tumHareketGecmisi"),
          icon: "pi pi-fw pi-star",
          to: "/tum-hareket-gecmisi",
          yetki: tumHareketGecmisi,
        },
        {
          label: t("leftMenu.tumMusteriSorumluKisiGecmisi"),
          icon: "pi pi-fw pi-star",
          to: "/tum-musteri-sorumlu-kisi-gecmisi",
          yetki: tumMusteriSorumluKisiGecmisi,
        },
        {
          label: t("leftMenu.musteriBilgileriRaporu"),
          icon: "pi pi-fw pi-user",
          to: "/customer-info-report",
          yetki: customerInfoAggregation,
        },
      ],
    },
    {
      label: t("leftMenu.dokumantasyon"),
      yetki: dokumanMenu, //dokumanMenu
      items: [
        {
          label: "Rol ve Yetkilendirme",
          icon: "pi pi-fw pi-book",
          to: "/dokuman-rol-ve-yetkilendirme",
          yetki: dokumanMenu,
        }, //dokumanMenu
      ],
    },
    {
      label: t("leftMenu.yetki"),
      yetki: "developer", //Eğer developer ise kullanıcı yeni yetki ekleyebilir.
      items: [
        {
          label: t("leftMenu.yetkiEkle"),
          icon: "pi pi-fw pi-cog",
          to: "/yeni-yetki",
          yetki: "developer",
        },
        {
          label: t("leftMenu.yetkiListesi"),
          icon: "pi pi-fw pi-cog",
          to: "/yetki-listesi",
          yetki: "developer",
        },
      ],
    },
    {
      label: t("leftMenu.ticket"),
      yetki: "developer", //Eğer developer ise kullanıcı yeni yetki ekleyebilir.
      items: [
        {
          label: t("leftMenu.ticketEkle"),
          icon: "pi pi-fw pi-users",
          to: "/yeni-ticket",
          yetki: "developer",
        },
        {
          label: t("leftMenu.ticketListesi"),
          icon: "pi pi-fw pi-users",
          to: "/ticket-listesi",
          yetki: "developer",
        },
      ],
    },
  ];

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  const authorityList = JwtHelper.getAuthority(); //Yetkileri getirir

  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <Toast ref={toast} />
      <Tooltip
        ref={copyTooltipRef}
        target=".block-action-copy"
        position="bottom"
        content="Copied to clipboard"
        event="focus"
      />

      <AppTopbar
        onToggleMenuClick={onToggleMenuClick}
        layoutColorMode={layoutColorMode}
        mobileTopbarMenuActive={mobileTopbarMenuActive}
        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
      />

      <div className="layout-sidebar" onClick={onSidebarClick}>
        <AppMenu
          model={menu}
          onMenuItemClick={onMenuItemClick}
          layoutColorMode={layoutColorMode}
        />
      </div>

      <div className="layout-main-container">
        <div className="layout-main">
          <Route
            path="/"
            exact
            render={() => (
              <Dashboard colorMode={layoutColorMode} location={location} />
            )}
          />

          {authorityList.includes(personelEkle) && (
            <Route path="/yeni-personel" component={PersonnelAdd} />
          )}
          {authorityList.includes(personelDetay) && (
            <Route path="/personel" component={PersonnelDetail} />
          )}

          {authorityList.includes(personelListesi) && (
            <Route path="/personel-listesi" component={personnelList} />
          )}

          {authorityList.includes(customerInfoAggregation) && (
            <Route path="/customer-info-report" component={customerInfo} />
          )}

          <Route path="/yeni-musteri" component={CustomerAdd} />
          <Route path="/musteri" component={CustomerDetail} />
          <Route path="/musteri-listesi" component={CustomerList} />
          <Route path="/yeni-toplu-musteri" component={CustomerAddBulk} />
          <Route
            path="/yeni-toplu-musteri-sorumlu-lead"
            component={CustomerAddBulkLeadPersonnel}
          />
          <Route path="/google-sheet-listesi" component={googleSheetsList} />
          <Route path="/web-form-listesi" component={WebFormList} />
          <Route path="/pasif-musteri-listesi" component={PasifCustomerList} />
          {/* <Route path="/musteri-indirme-listesi" component={CustomerDownloadList} /> */}

          <Route path="/yeni-takim" component={TeamAdd} />
          <Route path="/takim" component={TeamDetail} />
          <Route path="/takim-listesi" component={TeamList} />

          <Route path="/yeni-lead" component={LeadAdd} />
          <Route path="/lead" component={LeadDetail} />
          <Route path="/lead-listesi" component={LeadList} />

          <Route path="/yeni-ticket" component={TicketAdd} />
          <Route path="/ticket" component={TicketDetail} />
          <Route path="/ticket-listesi" component={TicketList} />

          <Route path="/yeni-kaynak" component={SourceAdd} />
          <Route path="/kaynak" component={SourceDetail} />
          <Route path="/kaynak-listesi" component={SourceList} />

          <Route path="/yeni-destek-talebi" component={SupportAdd} />
          <Route path="/destek-talebi-listesi" component={SupportList} />

          <Route path="/hatirlaticilarim" component={NotificationList} />

          <Route path="/yeni-rol" component={RoleAdd} />
          <Route path="/rol" component={RoleDetail} />
          <Route path="/rol-listesi" component={RoleList} />

          <Route path="/tum-musteri-notlari" component={AllCustomerNote} />
          <Route path="/tum-hareket-gecmisi" component={LogList} />
          <Route
            path="/tum-musteri-sorumlu-kisi-gecmisi"
            component={CustomerStatusList}
          />

          <Route
            path="/dokuman-rol-ve-yetkilendirme"
            component={RoleAndAuthorizationDokumantion}
          />

          <Route path="/yeni-yetki" component={AuthoritiesAdd} />
          <Route path="/yetki-listesi" component={AuthoritiesList} />

          <Route path="/mt4-kullanici-listesi" component={UserList} />
          <Route path="/mt4-trades-listesi" component={TradesList} />
          <Route
            path="/mt4-trades-degistirilmis-zaman-listesi"
            component={TradesModifyTimeDescGetList}
          />
        </div>
        <AppFooter layoutColorMode={layoutColorMode} />
      </div>

      <AppConfig
        rippleEffect={ripple}
        onRippleEffect={onRipple}
        inputStyle={inputStyle}
        onInputStyleChange={onInputStyleChange}
        layoutMode={layoutMode}
        onLayoutModeChange={onLayoutModeChange}
        layoutColorMode={layoutColorMode}
        onColorModeChange={onColorModeChange}
      />

      <CSSTransition
        classNames="layout-mask"
        timeout={{ enter: 200, exit: 200 }}
        in={mobileMenuActive}
        unmountOnExit
      >
        <div className="layout-mask p-component-overlay"></div>
      </CSSTransition>
    </div>
  );
};

export default Main;
