import React, { useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

// Prime Component
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';

/*Component*/
import ContentLoading from '../loading/ContentLoading';

import {NotificationGet} from "../../service/notification";
import {JwtHelper} from "../../plugins/jwtHelper";

const List = () => {
    const [isLoading,setIsLoading] = useState(true);
    const [notifications,setNotifications] = useState([]);

    useEffect(()=>{
        setInterval(()=> {
            NotificationGet(JwtHelper.getUserId()).then(data=>{
                console.log("notification data",data);
                setNotifications(data);
                setIsLoading(false)
            });
        },1000)
    },[])


    //Listelemeye düzenleme ve silme butonunu eklediğimiz kısım
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Link className="p-button-rounded p-button-success" target='_blank'
                      to={`/musteri/${rowData.customerId}`}>
                    <Button icon="pi pi-eye" className="p-button-rounded p-button-success mr-2"/>
                </Link>
            </React.Fragment>
        );
    }

    return (
        <>
            {isLoading ? (
                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <ContentLoading/>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <h5>Bildirim Listesi</h5>
                            <p>Bildirimler Burada Gözükmektedir.</p>
                            <DataTable value={notifications} responsiveLayout="scroll" paginator rows={10}
                                       emptyMessage="Herhangi Bir Bildirim Bulunuamadı.">
                                <Column field="content" header="Bildirim Açıklama"></Column>
                                <Column field="user.username" header="Oluşturan"></Column>
                                <Column body={actionBodyTemplate} exportable={false}
                                        style={{minWidth: '18rem'}}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>

            )}
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(List, comparisonFn);
